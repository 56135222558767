export const PROJECTS = [
  {
    id: 0,
    title: "Hackmamba",
    desc1: "Created a user-centric website featuring interactive elements ",
    desc2: "and aesthetic allure while prioritizing performance optimization ",
    desc3: "and cross-browser compatibility.",
    image: "pic",
    gradient: "gradient1",
    link: "https://hackmamba-dev.netlify.app/",
    keyboard: "Keyboard",
    text: "CTRL H"
  },
  {
    title: "Bitsika",
    id: 1,
    desc1: "I developed a visually appealing and user-friendly website for ",
    desc2: "Bitisika to effectively showcase the platform's features and ",
    desc3: "capabilities.",
    image: "pic2",
    gradient: "gradient2",
    link: "https://bitsika-test.netlify.app/",
    keyboard: "Keyboard2",
    text: "CTRL B"
  },
  {
    title: "Talkroom",
    id: 2,
    desc1: "I developed a user-friendly, visually appealing website for Ear1",
    desc2: "(now TalkRoom), highlighting its features to enhance ",
    desc3: "interactive conversations.",
    image: "pic3",
    gradient: "gradient3",
    link: "https://www.talkroom.com/",
    keyboard: "Keyboard3",
    text: "CTRL T"
  },
  {
    title: "Omegoat",
    id: 3,
    desc1: "Built a responsive giveaway site using HTML, CSS, and ",
    desc2: "JavaScript for Omegoat, aimed at boosting fan awareness and ",
    desc3: "providing exclusive concert incentives.",
    image: "pic4",
    gradient: "gradient4",
    link: "https://echoke-webapp.netlify.app/index.html",
    keyboard: "Keyboard4",
    text: "CTRL O"
  },
  {
    title: "Console",
    id: 4,
    desc1: "Worked with designers and developers to create a web app ",
    desc2: "enabling businesses to receive fee-free instant payments from  ",
    desc3: "Bitsika users across Africa.",
    image: "pic5",
    gradient: "gradient5",
    link: "https://bitsika-merchant.netlify.app/",
    keyboard: "Keyboard5",
    text: "CTRL C"
  },
];


           

